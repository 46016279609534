import { graphql } from 'gatsby';
import { Trans } from "gatsby-plugin-react-i18next";
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Layout from '../components/layout';
import SpecialBg from "../images/nigeria/special-bg.png";

const UxTermsAndConditions = () => {
  return (
    <Layout pageTitle="Terms and Conditions | Moniepoint ">
      <Helmet>
        <link rel="alternate" href="https://moniepoint.com/ux-terms-and-conditions" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ux-terms-and-conditions" hreflang="en-ng" />
      </Helmet>
      <GetStartedContainer>
        <div className="bg-mask"></div>
        <div className="content">
          <div className="get-started-section">
            <h2 className={`title`}>
              <Trans>TERMS AND CONDITIONS FOR PARTICIPATION IN USER EXPERIENCE RESEARCH STUDY</Trans>
            </h2>
          </div>
        </div>
      </GetStartedContainer>
      <ContentContainer>
        <div className="content">
          <div>
            <div className="pp-list-outer">
              <p className="pp-list-copy top">These terms and conditions set out the terms for participating in user experience research studies conducted by Moniepoint Inc. and/or its affiliates (collectively referred to as “Moniepoint” or “We”) from time to time (the “Study”). By participating in any Study, you agree to abide by these terms and conditions. If you do not agree with these terms and conditions, you should not participate in any Study.
              </p>
              <p className="pp-list-copy">We reserve the right to amend these terms and conditions at any time and at our
                sole discretion. We shall give you notice of such amendment by publication of the amended version
                via <a href="https://moniepoint.com/ux-terms-and-conditions" target="_blank"
                  rel="noopener noreferrer">moniepoint.com/ux-terms-and-conditions</a>. Your
                continued participation in any Study is your
                acceptance of the updated terms. If you disagree with any part of these terms and conditions, do not
                participate further in any Study.
              </p>
              <p className="pp-list-copy"> Minors (currently age 18) are not allowed to participate in any Study or accept these terms and conditions.</p>

            </div>
            <div className="pp-list-outer">
              <h1 className="pp-list-header">PARTICIPATION</h1>
              <p className="pp-list-copy">The purpose of the Study is to enable us to have a better understanding of the user experience of our products and services. Your participation in the Study will help us modify, develop, or otherwise improve our products and services. The Study will be with a representative of Moniepoint and would be either remote or in-person, depending on your chosen preference. </p>
              <p className="pp-list-copy">The Study may consist of answering questionnaires, testing products and services or other methods of providing feedback.</p>
              <p className="pp-list-copy">Participation in any Study is voluntary, and you may withdraw your participation at any time without penalty by using the contact information provided in this terms and conditions.  </p>
            </div>
            <div className="pp-list-outer">
              <h1 className="pp-list-header">COMPENSATION</h1>
              <p className="pp-list-copy">You understand and agree that you may receive compensation for participating in any Study.  Details of any applicable compensation shall be communicated to you by Moniepoint.</p>
            </div>
            <div className="pp-list-outer">
              <h1 className="pp-list-header">CONFIDENTIALITY</h1>
              <p className="pp-list-copy">Information and content made available to you in or for a Study may contain trade secrets or other confidential or proprietary information. You must hold in strict confidence and not disclose to any other person any information and content that you access or learn in connection with your participation in any Study. You must not use any such information or content for any purpose other than your participation in a Study in accordance with these terms and conditions. You shall notify Moniepoint immediately you learn of or suspect any use or disclosure of, or access to, any such information or content other than as specifically authorized in these terms and conditions.

              </p>
            </div>
            <div className="pp-list-outer">
              <h1 className="pp-list-header">RESPECTFUL COMMUNICATION</h1>
              <p className="pp-list-copy">If you communicate with any representative of Moniepoint (“Personnel”), you agree to do so in a respectful manner. You shall not use profanity, threats, insults, or other disrespectful or inappropriate language or behavior when communicating with a Personnel, including but not limited to in-person meetings, phone calls, live chats and emails. </p>

            </div>
            <div className="pp-list-outer">
              <h1 className="pp-list-header">INTELLECTUAL PROPERTY OWNERSHIP</h1>
              <p className="pp-list-copy">You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, original or creative materials, or other information or content provided by you to Moniepoint (other than your Personal Information), will become the sole property of Moniepoint. Moniepoint will own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of such information and content for any purpose, commercial or otherwise, without acknowledgment or compensation to you. You also grant Moniepoint a continuous, non-reversible license to use the information and content for any purpose.</p>
              <p className="pp-list-copy">Moniepoint grants no rights, either express or implied, to you under these terms and conditions or by your participation in any Study. For the avoidance of doubt nothing in these terms and conditions will be construed as conferring any license or ownership to intellectual property rights, whether by estoppel, implication or otherwise.</p>

            </div>
            <div className="pp-list-outer">
              <h1 className="pp-list-header">WARRANTIES</h1>
              <p className="pp-list-copy">You represent and warrant that you have the lawful right to provide the responses, ideas, feedback, or other information or content, including personal information that you have submitted to Moniepoint (the “Content”).</p>
              <p className="pp-list-copy">You must not provide any Content that:
                <ul className="pp-list inner">
                  <li className="">Is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, false, inaccurate, misleading, fraudulent, or impersonates or misrepresents an affiliation with any person or entity;
                  </li>
                  <li>Would violate an obligation of confidentiality or the rights of any person or entity, or that would otherwise create liability or violate any local, state, national, or international law;
                  </li>
                  <li>May infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of any person or entity;
                  </li>
                  <li>Contains any private information of any person or entity, including, without limitation, addresses, phone numbers, email addresses; or
                  </li>
                  <li>Contains any viruses, corrupted data or other harmful, disruptive, or destructive files or information.
                  </li>
                </ul>
              </p>
            </div>
            <div className="pp-list-outer">
              <h1 className="pp-list-header">INDEMNIFICATION</h1>
              <p className="pp-list-copy">You will indemnify and hold Moniepoint harmless from and against any costs, damages, expenses, and liabilities (including, but not limited to, reasonable attorneys' fees) arising out of or related to any Content, your participation in a Study, your violation of these terms and conditions, or any other consequence of your acts or omissions.</p>

            </div>

            <div className="pp-list-outer">
              <h1 className="pp-list-header">LIMITATION OF LIABILITY</h1>
              <p className="pp-list-copy">By participating in any Study, you acknowledge that you are doing so voluntarily, and you agree to assume all risks associated with your participation and to waive any claims you may have against Moniepoint or any of its affiliates, directors, officers, employees, agents, representatives, successors, or assigns for any harm, injury, or damage resulting from your participation in any Study. Moniepoint shall have no liability for any injury, loss, claim, or damage arising out of or in connection with your participation in any Study.</p>

            </div>

            <div className="pp-list-outer">
              <h1 className="pp-list-header">PRIVACY STATEMENT</h1>
              <p className="pp-list-copy">Your permit Moniepoint to record you in any format during the Study, including recording your use of its website, products or services, measurements, eye movements, answers, communications, and voice through any means, including without limitation, audio, video, photography, and screen captures (“Recordings”). In addition to your information collected via Recordings, Moniepoint may also collect your personal information, including but not limited to your name, age, gender, address, email address, phone number, and personal background (this additional information, along with the Recordings, will be collectively referred to Personal Information”).

              </p>
              <p className="pp-list-copy">Your Personal Information will be kept confidential and shall be treated in compliance with the relevant provisions of the Nigeria Data Protection Act 2023 (and any amendment thereto) and all relevant laws.   We may also anonymize your responses and aggregate them with the responses of other participants in order to share Study results externally.</p>
              <p className="pp-list-copy">We will not request, and you should not provide, any sensitive personal information during any Study.</p>
              <p className="pp-list-copy">You permit for your Personal Information to be used for our internal purposes as we continue to improve our products and services and such use may be for the following purposes:
                <ul className="pp-list inner">
                  <li>communicating with you;
                  </li>
                  <li>improving our product/service delivery;
                  </li>
                  <li>research and analysis;
                  </li>
                  <li>providing, maintaining and improving our products and services; and/or
                  </li>
                  <li>responding to your enquiries and requests.
                  </li>
                </ul>
              </p>
              <p className="pp-list-copy">You permit for us to disclose your Personal Information for the following purposes:
                <ul className="pp-list inner">
                  <li>to a regulatory authority;
                  </li>
                  <li>to our service providers that perform services on your behalf; and/or
                  </li>
                  <li>if required to be disclosed under applicable law, or stock exchange regulation or by a government order, decree, regulation or rule or by a court order.
                  </li>
                </ul>
              </p>
              <p className="pp-list-copy">If you provide any Personal Information, you grant Moniepoint a non-exclusive, royalty-free, perpetual, irrevocable and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such Personal Information throughout the world in any media, without acknowledgement or compensation to you, in such circumstance no personal identifiers will be included.

              </p>
            </div>

            <div className="pp-list-outer">
              <h1 className="pp-list-header">GOVERNING LAW</h1>
              <p className="pp-list-copy">You acknowledge and agree that these terms and conditions will be interpreted and enforced in accordance with the laws of the Federal Republic of Nigeria. Any controversy or claim arising out of or relating to these terms and conditions or any matter related thereto shall be submitted to a court of competent jurisdiction in the Federal Republic of Nigeria.

              </p>
            </div>


            <div className="pp-list-outer">
              <h1 className="pp-list-header">MISCELLANEOUS PROVISIONS</h1>
              <p className="pp-list-copy">Moniepoint’s failure to act in a particular circumstance does not waive its ability to act with respect to that circumstance or similar circumstances. </p>
              <p className="pp-list-copy">
                Any provision of these terms and conditions that is found to be invalid, unlawful, or unenforceable will be severed from these terms and conditions, and the remaining provisions will continue to be in full force and effect.
              </p>

              <p className="pp-list-copy">The headings and titles in these terms and conditions are for convenience only and have no legal or contractual effect. </p>
              <p className="pp-list-copy">
                Any provisions in these terms and conditions that by their nature should survive the termination of these terms and conditions or any Study activity (including, without limitation, provisions governing indemnification, limitations on liability, warranties, and ownership of intellectual property) will continue to remain in full force and effect after the termination of thereto.
              </p>

            </div>


          </div>
        </div>
      </ContentContainer>
    </Layout>
  )
}

export default UxTermsAndConditions


export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const GetStartedContainer = styled.section`
  background: rgba(3, 87, 238, 1);
  background-size: fill;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  color: white;
  position: relative;

  .bg-mask {
    background: url(${SpecialBg});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    opacity: 0.4;
  }

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }

    .get-started-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 58px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 657px;
        margin-bottom: 8px;
      }

      .description {
        max-width: 623px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }

      .get-started-button {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
        margin: 0 auto;
        padding: 16px 24px;
        border: none;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .content {
      padding: 81px 30px 98px;

      .get-started-section {
        .title {
          font-size: 32px;
          line-height: 35.04px;
          margin-bottom: 16px;
        }

        .description {
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
`;

const ContentContainer = styled.section`
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }


    .pp-list {
      padding-left: 0;

      &.inner {
        margin-top: 8px;
        padding-left: 30px;
        list-style-position: inside;
      }

      ul {
        list-style-type: disc;
      }

      &.sub {
        list-style-type: circle;
      }
    }

    .pp-list-outer {
      margin-bottom: 40px;
      list-style-position: inside;

      &::marker {
        font-size: 32px;
        font-weight: bold;
      }
    }

    .pp-list-header {
      font-family: "Founders Grotesk";
      font-style: normal;
      font-weight: bolder;
      font-size: 32px;
      line-height: 38px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
      display: inline-block;
      line-height: 1.5;

      @media only screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    .pp-list-copy {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      opacity: 0.8;
      margin-bottom: 12px;

      &.top {
        margin-top: 10px;
      }

      b {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .pp-list-sub {
      padding-left: 0;
      counter-reset: item;
    }

    .pp-list-header {
      font-family: "Founders Grotesk";
      font-weight: 500;
      font-size: 24px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
      display: inline-block;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    li > b {
      color: rgba(0, 0, 0, 1);
    }

    .pp-list-outer-sub {
      margin-bottom: 16px;

      ul ul {
        li {
          list-style-type: circle;
        }
      }
    }

    ol > li {
      counter-increment: item;
    }

    ol.pp-list-sub > li {
      display: block;
    }

    ol.pp-list-sub > li:before {
      font-size: 24px;
      font-weight: 500;
      content: counters(item, ".") ". ";

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .pp-table {
      margin: 16px auto;
      border: 1px solid;

      th, td {
        border: 1px solid;
        padding: 6px;
        font-size: 14px;
      }
    }


    .image-container {
      display: flex;
      gap: 16px;

      .pp-image {
        width: 100%;
        height: 100%;
        max-width: 512px;
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 30px;
    }
  }
`;